.loading-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.loader-liquid {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loader-z {
  width: 120px;
  height: auto;
  opacity: 0.9;
  animation: pulse 2s infinite;
}

.loading-dots {
  display: flex;
  justify-content: center;
  gap: 6px;
}

.loading-dots span {
  width: 8px;
  height: 8px;
  background-color: #1f5a2d;
  border-radius: 50%;
  display: inline-block;
  animation: dotFade 1.4s infinite ease-in-out;
}

.loading-dots span:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-dots span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes dotFade {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.7;
  }
}
